<template>
  <div class="common">
    <h6>基础信息</h6>
    <el-button link type="primary" class="backall" @click="cancelBack">
      返回上一级
    </el-button>
    <div class="commonmask" v-if="routeStatus == 'details'"></div>
    <el-form :model="form" :rules="rules" label-width="120px" ref="formRef">
      <el-form-item label="优惠券名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" :maxlength="32" />
      </el-form-item>
      <el-form-item
        label="发放数量"
        :prop="!form.sendTotalTypeFlag ? 'sendTotal' : ''"
      >
        <el-input
          placeholder="请输入"
          :disabled="form.sendTotalTypeFlag"
          v-model="form.sendTotal"
          @keyup="numberInput"
        />
        <!-- true 1  false 2 -->
        <el-checkbox
          label="不限数量"
          v-model="form.sendTotalTypeFlag"
          @change="handleChangeSendTotalType"
        />
      </el-form-item>
      <el-form-item>
        <p>编辑进行中的优惠券，发放总量只能增加不能减少，请谨慎设置。</p>
      </el-form-item>
      <el-form-item label="卡券类型" prop="type">
        <el-select
          placeholder="请选择"
          @change="handleChangeType"
          v-model="form.type"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, i) in typeList"
            :key="i"
          />
        </el-select>
      </el-form-item>
      <!-- 有赞券start -->
      <template v-if="form.type == 2">
        <el-form-item label="有赞优惠券名称" prop="externalId">
          <el-select
            v-model="form.externalId"
            filterable
            remote
            placeholder="可按名称模糊匹配"
            :remote-method="youzanremoteMethod"
            @change="handleChangeExternal"
          >
            <el-option
              v-for="item in externalIdList"
              :key="item.groupId"
              :label="item.title"
              :value="item.groupId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="有赞优惠券内容" prop="content">
          <el-input
            placeholder="请输入"
            type="textarea"
            :rows="4"
            v-model="form.content"
          />
        </el-form-item>
      </template>
      <!-- 有赞券end -->

      <!-- 现金满减券start -->
      <template v-if="form.type == 1 || form.type == 2 || form.type == 5">
        <el-form-item label="使用范围" prop="useGoodsType">
          <el-radio-group v-model="form.useGoodsType">
            <el-radio
              :label="item.value"
              v-for="(item, i) in useGoodsTypeList"
              :key="i"
              :disabled="form.type == 2"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.useGoodsType != 1">
          <el-form-item>
            <el-button type="primary" @click="getGoods">选择商品</el-button>
            <p style="margin-left: 10px">最多支持选择25件已上架的商品</p>
          </el-form-item>
          <el-form-item
            class="form_date form_date_table"
            v-if="specifyTableData.length > 0"
          >
            <TableView
              :columns="specifyColumns"
              :data="specifyTableData"
              :is-pagination-show="false"
              wrapperHeight="450"
            >
              <template #description>
                <el-table-column label="商品信息" align="center">
                  <template #default="scoped">
                    <div class="desc">
                      <img :src="scoped.row.image" style="width: 50px" />
                      <span>{{ scoped.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
              </template>
              <template #action>
                <el-table-column label="清空所有" width="100" align="center">
                  <template #default="scoped">
                    <el-button
                      type="primary"
                      size="small"
                      @click="handleClickDelete(scoped.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </template>
            </TableView>
          </el-form-item>
        </template>
        <el-form-item label="使用门槛" prop="conditionType">
          <el-radio-group
            v-model="form.conditionType"
            @change="handleChangeConditionType"
          >
            <el-radio :label="1" :disabled="form.type == 2">无门槛</el-radio>
            <el-radio :label="2" :disabled="form.type == 2">
              订单满
              <el-input
                placeholder="请输入"
                :disabled="form.conditionType == 1 || form.type == 2"
                v-model="form.conditionMoney"
                style="width: 100px; margin-left: 10px"
                @keyup="handleInput"
              />
              元可用
            </el-radio>
          </el-radio-group>
          <el-form-item label="" v-if="form.type == 5">
            <span style="color: #409eff; padding-right: 5px">，打</span>
            <el-input
              style="width: 100px"
              placeholder="请输入"
              v-model="form.money"
              :disabled="form.conditionType == 1 || form.type == 2"
              @keyup="handleInput"
            />
            <span style="color: #409eff">折</span>
          </el-form-item>
        </el-form-item>
        <el-form-item label="卡券金额" prop="money" v-if="form.type != 5">
          <el-input
            placeholder="请输入"
            v-model="form.money"
            :disabled="form.type == 2"
            @keyup="handleInput"
          />元
        </el-form-item>
      </template>
      <!-- 现金满减券end -->

      <!-- 活动资格券start -->
      <template v-if="form.type == 3">
        <!-- 预约列表接口 -->
        <el-form-item label="关联预约活动" prop="activityIds">
          <el-select
            v-model="form.activityIds"
            filterable
            remote
            multiple
            placeholder="可按名称模糊匹配"
            :remote-method="huodremoteMethod"
          >
            <el-option
              v-for="item in activityIdsList"
              :key="item.id"
              :label="`${item.activityName + item.subtitle}`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="卡券内容" prop="content">
          <el-input
            placeholder="请输入"
            type="textarea"
            :rows="4"
            v-model="form.content"
          />
        </el-form-item>
      </template>
      <!-- 活动资格券end -->
      <h6>领取设置</h6>
      <el-form-item label="领取方式" class="mb-0" prop="getType">
        <el-radio-group v-model="form.getType">
<!--          v-if="form.type != 2"-->
          <el-radio
            :label="item.value"
            v-for="(item, i) in getTypeList"
            :key="i"
          >
            {{ item.label }}
          </el-radio>
<!--          <el-radio v-if="form.type == 2" :label="2"> 系统发放 </el-radio>-->
        </el-radio-group>
<!--        <span v-if="form.type == 2" style="padding-left: 20px">-->
<!--          有赞券不支持【用户领取】-->
<!--        </span>-->
      </el-form-item>
      <el-form-item>
        <p>
          用户领取：指用户自行领取；系统发放：指通过后台给某个用户或指定群体用户发放（手动发放）
        </p>
      </el-form-item>
      <el-form-item
        label="会员等级限制"
        v-if="form.getType == 1"
        prop="levelLimit"
      >
        <el-select placeholder="请选择" v-model="form.levelLimit">
          <el-option
            v-for="item in levelLimitList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          />
        </el-select>
        起
      </el-form-item>
      <el-form-item label="领券时间" class="form_date" prop="getTime">
        <el-date-picker
          v-model="form.getTime"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :default-time="defaultTime"
          :disabled-date="disabledDate"
        />
      </el-form-item>
      <template v-if="form.getType == 1">
        <template v-if="form.type != 2">
          <el-form-item label="领券次数" class="mb-0" prop="getNumType">
            <el-radio-group
              v-model="form.getNumType"
              @change="handleChangeGetNumType"
            >
              <el-radio
                :label="item.value"
                v-for="(item, i) in getNumTypeList"
                :key="i"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.getNumType != 1">
            <el-input
              placeholder="请输入"
              v-model="form.getNum"
              @keyup="numberInput"
            />次
          </el-form-item>
        </template>
      </template>
      <h6>用券规则</h6>
      <el-form-item label="用券时间" class="mb-0" prop="useTimeType">
        <el-radio-group
          v-model="form.useTimeType"
          @change="handleChangeUseTimeType"
        >
          <el-radio
            :label="item.value"
            v-for="(item, i) in useTimeTypeList"
            :key="i"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="form_date">
        <el-date-picker
          v-if="form.useTimeType == 1"
          v-model="form.useTimeArr"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :default-time="defaultTime"
        />
        <div v-if="form.useTimeType != 1">
          <el-input
            placeholder="请输入"
            v-model="form.useTime"
            @keyup="numberInput"
          />天内可用
        </div>
      </el-form-item>
      <el-form-item label="卡券内容" prop="content" v-if="form.type == 1">
        <el-input
          placeholder="请输入"
          type="textarea"
          :rows="4"
          v-model="conotent"
        />
      </el-form-item>
      <el-form-item v-if="routeStatus != 'details'">
        <el-button type="primary" @click="onSubmit" v-if="routeStatus == ''">
          创建卡券
        </el-button>
        <el-button
          type="primary"
          @click="onSubmit"
          v-if="routeStatus == 'edit'"
        >
          修改卡券
        </el-button>
        <el-button @click="cancelBack">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      v-model="cardsVisible"
      title="选择卡券商品"
      width="800px"
      :before-close="cardsClose"
    >
      <FormView
        :formData="fromData"
        :formLabels="formLabels"
        :column="formColumn"
        :labelWidth="labelWidth"
      >
        <el-button type="warning" @click="reset">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </FormView>
      <el-table
        v-loading="shopLoading"
        :data="shopTableData"
        height="450"
        style="width: 100%"
      >
        <el-table-column label="选择" width="60">
          <template #header>
            <el-checkbox v-model="selectAll"></el-checkbox>
          </template>
          <template #default="{ row }">
            <div class="flex row-center" @click.stop>
              <el-checkbox
                :model-value="selectItem(row)"
                @change="handleSelect($event, row)"
              ></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" width="380" align="center">
          <template #default="scoped">
            <div class="desc">
              <img :src="scoped.row.image" style="width: 50px" />
              <span>{{ scoped.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="库存" align="center" prop="totalStock">
        </el-table-column>
        <el-table-column label="价格" align="center">
          <template #default="scoped">
            {{ initPrice(scoped.row) }}
          </template>
        </el-table-column>
        <el-table-column label="销售状态" align="center">
          <template #default="scoped">
            {{ scoped.row.state == 0 ? '下架' : '上架' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px"
        :current-page="shopPagination.current"
        :page-size="shopPagination.size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="shopPagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cardsClose"> 取消 </el-button>
          <el-button type="primary" @click="checkoutSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, h, toRefs, ref, nextTick, computed } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { goodsCategoryTree } from '@/api/productManagement/productClassify'
import { activityList } from '@/api/themeConfiguration'
import { couponList } from '@/api/cardManagement/youzan'
import {
  couponAdd,
  couponEdit,
  couponDetail
} from '@/api/cardManagement/platform'
import { queryLevel } from '@/api/productManagement/productList'
import { queryCriteria } from '@/api/productManagement/productList'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const formRef = ref()
const initData = reactive({
  formLabels: [
    {
      label: 'goodsCategoryId',
      title: '商品分类',
      placeholder: '请选择',
      type: 'options',
      options: []
    },
    {
      label: 'keyword',
      title: '商品搜索',
      placeholder: '请输入关键字以搜索'
    }
  ],
  fromData: {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  },
  formColumn: 2.5,
  labelWidth: '90px',
  defaultTime: [
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59)
  ],
  form: {
    id: '',
    name: '',
    type: 1,
    sendTotal: '',
    sendTotalType: 2,
    sendTotalTypeFlag: false,
    useGoodsType: 1,
    conditionType: 1,
    conditionMoney: '',
    money: '',
    zhekou: '',
    activityIds: [],
    content: '',
    externalId: '',
    getType: 1,
    levelLimit: '0',
    getTime: [],
    getTimeStart: '',
    getTimeEnd: '',
    useTimeArr: [],
    useTimeStart: '',
    useTimeEnd: '',
    getNumType: 1,
    useTimeType: 1
  },
  rules: {
    name: [
      {
        required: true,
        message: '请输入优惠券名称',
        trigger: 'blur'
      }
    ],
    sendTotal: [
      {
        required: true,
        message: '请输入发放数量',
        trigger: 'blur'
      }
    ],
    type: [
      {
        required: true,
        message: '请选择卡券类型',
        trigger: 'change'
      }
    ],
    useGoodsType: [
      {
        required: true,
        message: '请选择使用范围',
        trigger: 'change'
      }
    ],
    conditionType: [
      {
        required: true,
        message: '请选择使用门槛',
        trigger: 'change'
      }
    ],
    money: [
      {
        required: true,
        message: '请输入卡券金额',
        trigger: 'blur'
      }
    ],
    zhekou: [
      {
        required: true,
        message: '请输入折扣',
        trigger: 'blur'
      }
    ],
    activityIds: [
      {
        required: true,
        message: '请选择关联预约活动',
        trigger: 'blur'
      }
    ],
    content: [
      {
        required: true,
        message: '请输入卡券内容',
        trigger: 'blur'
      }
    ],
    externalId: [
      {
        required: true,
        message: '请选择有赞优惠券名称',
        trigger: 'blur'
      }
    ],
    getType: [
      {
        required: true,
        message: '请选择领取方式',
        trigger: 'change'
      }
    ],
    levelLimit: [
      {
        required: true,
        message: '请选择会员等级限制',
        trigger: 'change'
      }
    ],
    getTime: [
      {
        required: true,
        message: '请选择领券时间',
        trigger: 'change'
      }
    ],
    getNumType: [
      {
        required: true,
        message: '请选择领券次数',
        trigger: 'change'
      }
    ],
    useTimeType: [
      {
        required: true,
        message: '请选择用券时间',
        trigger: 'change'
      }
    ]
  },
  typeList: [
    {
      label: '现金满减券',
      value: 1
    },
    {
      label: '有赞券',
      value: 2
    },
    {
      label: '活动券',
      value: 3
    },
    {
      label: '折扣券',
      value: 5
    }
  ],
  useGoodsTypeList: [
    {
      label: '全部通用',
      value: 1
    },
    {
      label: '指定商品可用',
      value: 2
    },
    {
      label: '指定商品不可用',
      value: 3
    }
  ],
  getTypeList: [
    {
      label: '用户领取',
      value: 1
    },
    {
      label: '系统发放',
      value: 2
    }
  ],
  getNumTypeList: [
    {
      label: '不限制领取次数',
      value: 1
    },
    {
      label: '限制领取次数',
      value: 2
    },
    {
      label: '每天限制领取次数',
      value: 3
    }
  ],
  useTimeTypeList: [
    {
      label: '固定时间',
      value: 1
    },
    {
      label: '领券当日起',
      value: 2
    },
    {
      label: '领券次日起',
      value: 3
    }
  ],
  levelLimitList: [],
  externalIdList: [],
  activityIdsList: [],
  specifyColumns: [{ slot: 'description' }, { slot: 'action' }],
  changeCurrentData: [],
  specifyTableData: [],
  // 选择商品弹窗数据
  cardsVisible: false,
  shopLoading: false,
  shopTableData: [],
  specifySelectTableData: [],
  shopPagination: {
    current: 1,
    size: 10,
    total: 0
  },
  shopIsPaginationShow: true,
  routeStatus: '',
  selectAll: []
})

const {
  cardsVisible,
  form,
  rules,
  typeList,
  useGoodsTypeList,
  getTypeList,
  getNumTypeList,
  useTimeTypeList,
  specifyColumns,
  changeCurrentData,
  specifyTableData,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  shopLoading,
  shopTableData,
  shopPagination,
  activityIdsList,
  externalIdList,
  routeStatus,
  defaultTime,
  levelLimitList
} = toRefs(initData)

onMounted(() => {
  getClassifyDataList()
  getLevel()
  if (route.query.id) {
    routeStatus.value = route.query.flag
    getData(route.query.id)
  }
})

const getLevel = () => {
  queryLevel().then(({ data: res }) => {
    if (res.code == 200) {
      levelLimitList.value = res.data
    } else {
      ElMessage.warning(res.msg)
    }
  })
}

const conotent = computed(() => {
  let val = ''
  useGoodsTypeList.value.forEach(item => {
    if (item.value == form.value.useGoodsType) {
      val += item.label + '；'
    }
  })
  if (form.value.conditionType == 2) {
    if (form.value.conditionMoney != '') {
      val += `订单满${form.value.conditionMoney},`
    }
  } else {
    val += '无门槛；'
  }
  if (form.value.money != '') {
    val += `减${form.value.money}元；`
  }
  if (form.value.getType == 1) {
    val += `会员等级限制为Lv${form.value.levelLimit}起；`
  }
  if (form.value.useTimeType == 1) {
    if (form.value.useTimeArr.length > 0) {
      val += `用券时间为${
        form.value.useTimeArr[0] + '~' + form.value.useTimeArr[1]
      }；`
    }
  }
  if (form.value.useTimeType == 2) {
    if (form.value.useTime != '') {
      val += `用券时间为领券当日起${form.value.useTime}天内可用；`
    }
  }
  if (form.value.useTimeType == 3) {
    if (form.value.useTime != '') {
      val += `用券时间为领券次日起${form.value.useTime}天内可用；`
    }
  }
  form.value.content = val
  return val
})

const disabledDate = time => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  // 获取选择的日期
  const selectedDay = new Date(time)
  selectedDay.setHours(0, 0, 0, 0)
  // 禁止选择今日之前的日期
  return selectedDay < today
}

const getData = id => {
  couponDetail({ id: id }).then(({ data: res }) => {
    if (res.code == 200) {
      if (res.data.type == 2) {
        youzanremoteMethod()
      }
      if (res.data.type == 3) {
        huodremoteMethod()
      }
      form.value = res.data
      form.value.levelLimit = res.data.levelLimit?String(res.data.levelLimit):'0'
      form.value.sendTotalTypeFlag = res.data.sendTotalType == 1 ? true : false
      specifyTableData.value = res.data.goodsList || []
      if (res.data.getTimeStart) {
        form.value.getTime = [res.data.getTimeStart, res.data.getTimeEnd]
      } else {
        form.value.getTime = []
      }
      if (res.data.useTimeStart) {
        form.value.useTimeArr = [res.data.useTimeStart, res.data.useTimeEnd]
      } else {
        form.value.useTimeArr = []
      }
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 取消
const cancelBack = () => {
  router.push('/platform')
}

// 提交
const onSubmit = async () => {
  if (!formRef.value) return
  await formRef.value.validate((valid, fields) => {
    if (valid) {
      let data = {
        name: form.value.name,
        type: form.value.type,
        getType: form.value.getType,
        sendTotalType: form.value.sendTotalType,
        getTimeStart: form.value.getTime[0],
        getTimeEnd: form.value.getTime[1],
        content: form.value.content,
        useTimeType: form.value.useTimeType
      }
      if (form.value.sendTotalType == 2) {
        data.sendTotal = form.value.sendTotal
      }
      if (form.value.getType == 1) {
        data.levelLimit = form.value.levelLimit
        data.getNumType = form.value.getNumType
        if (form.value.getNumType != 1) {
          if (form.value.getNum != '') {
            data.getNum = form.value.getNum
          } else {
            ElMessage.warning('请输入领券次数')
            return false
          }
        }
      } else {
        data.getNumType = 1
      }
      if (form.value.type == 1 || form.value.type == 2) {
        data.useGoodsType = form.value.useGoodsType
        data.conditionType = form.value.conditionType
        data.money = form.value.money
        if (form.value.useGoodsType != 1) {
          if (specifyTableData.value.length > 0) {
            data.useGoodsIds = specifyTableData.value.map(item => item.id)
          } else {
            ElMessage.warning('请选择商品')
            return false
          }
        } else {
          specifyTableData.value = []
        }
        if (form.value.conditionType == 2) {
          if (form.value.conditionMoney != '') {
            data.conditionMoney = form.value.conditionMoney
          } else {
            ElMessage.warning('请输入门槛金额')
            return
          }
        }
      }
      if (form.value.type == 2) {
        data.externalId = form.value.externalId
      }
      if (form.value.useTimeType == 1) {
        if (form.value.useTimeArr.length != 0) {
          data.useTimeStart = form.value.useTimeArr[0]
          data.useTimeEnd = form.value.useTimeArr[1]
        } else {
          ElMessage.warning('请选择用券固定时间')
          return false
        }
      } else {
        if (form.value.useTime != '') {
          data.useTime = form.value.useTime
        } else {
          ElMessage.warning('请输入用券时间')
          return false
        }
      }
      if (form.value.type == 3) {
        data.activityIds = form.value.activityIds
      }
      if (form.value.id == '') {
        couponAdd(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            setTimeout(() => {
              router.push('/platform')
            }, 1000)
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = form.value.id
        couponEdit(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            setTimeout(() => {
              router.push('/platform')
            }, 1000)
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 有赞优惠券名称
const youzanremoteMethod = query => {
  // if (query) {
  let data = {
    current: 1,
    size: 9999
  }
  if (query) {
    data.keyword = query
  }
  couponList(data).then(({ data: res }) => {
    if (res.code == 200) {
      externalIdList.value = res.data
    } else {
      externalIdList.value = []
      ElMessage.error(res.msg)
    }
  })
  // } else {
  //   externalIdList.value = []
  // }
}

const rangeType = val => {
  let vals = ''
  switch (val) {
    case 'all':
      vals = 1
      break
    case 'part':
      vals = 2
      break
    case 'exclude':
      vals = 3
      break
  }
  return vals
}

// 关联预约活动
const huodremoteMethod = query => {
  // if (query) {
  let data = {
    keyword: query,
    current: 1,
    size: 9999
  }
  activityList(data).then(({ data: res }) => {
    if (res.code == 200) {
      activityIdsList.value = res.data.records
    } else {
      ElMessage.error(res.msg)
    }
  })
  // } else {
  //   activityIdsList.value = []
  // }
}

// 卡券类型change
const handleChangeType = val => {
  // 现金满减券
  form.value.useGoodsType = 1
  specifyTableData.value = []
  form.value.conditionType = 1
  form.value.money = ''
  form.value.conditionMoney = ''
  form.value.getTime = []
  form.value.activityIds = []
  form.value.content = ''
  form.value.externalId = []
  form.value.getTimeStart = ''
  form.value.getTimeEnd = ''
  form.value.getNumType = 1
  form.value.getNum = ''
  form.value.useTimeType = 1
  form.value.useTimeArr = []
  form.value.useTimeStart = ''
  form.value.useTimeEnd = ''
  form.value.useTime = ''
  // 有赞券
  if (val == 2) {
    form.value.getType = 2
  } else {
    form.value.getType = 1
  }

  // 活动券
  if (val != 3) {
    getNumTypeList.value = getNumTypeList.value.filter(item => item.value != 3)
  }
  if (val == 1) {
    getNumTypeList.value.push({
      label: '每天限制领取次数',
      value: 3
    })
  }
}

// 正整数限制
const numberInput = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 正整数及小数点
const handleInput = e => {
  // 正则表达式匹配，只允许输入正数和小数
  e.target.value = e.target.value.replace(/[^\d.]/g, '')
  // 如果有小数点，保留两位小数
  const dotIndex = e.target.value.indexOf('.')
  if (dotIndex !== -1) {
    e.target.value = e.target.value.substr(0, dotIndex + 3)
  }
}

// 领券次数change
const handleChangeGetNumType = val => {
  form.value.getNum = ''
}

// 发放数量change
const handleChangeSendTotalType = val => {
  form.value.sendTotalTypeFlag = val
  form.value.sendTotal = ''
  form.value.sendTotalType = val ? 1 : 2
}

// 用券时间change
const handleChangeUseTimeType = () => {
  form.value.useTimeArr = []
  form.value.useTime = ''
}

// 使用门槛change
const handleChangeConditionType = () => {
  form.value.conditionMoney = ''
}

// 选择商品弹窗
const getGoods = () => {
  if (specifyTableData.value.length == 25) {
    ElMessage.warning('已关联25条商品数据')
    return false
  }
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataList()
  cardsVisible.value = true
}

// 关闭商品弹窗
const cardsClose = () => {
  fromData.value = {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  }
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  cardsVisible.value = false
  if (specifyTableData.value.length == 0) {
    changeCurrentData.value = []
  }
  if (changeCurrentData.value.length == 0) {
    specifyTableData.value = []
  }
  const ids = specifyTableData.value.map(item => item.id)
  const cus = changeCurrentData.value.map(item => item.id)
  if (ids.length > cus.length) {
    let item = specifyTableData.value.filter(itemB => !cus.includes(itemB.id))
    changeCurrentData.value.push(...item)
  } else {
    changeCurrentData.value = changeCurrentData.value.filter(itemB =>
      ids.includes(itemB.id)
    )
  }
}

// 表格全选
const selectAll = computed({
  get: () => {
    if (!changeCurrentData.value) return false
    const ids = changeCurrentData.value.map(item => item.id)
    if (!shopTableData.value.length) {
      return false
    }
    return shopTableData.value.every(item => ids.includes(item.id))
  },
  set: val => {
    if (val) {
      for (let i = 0; i < shopTableData.value.length; i++) {
        const item = shopTableData.value[i]
        const ids = changeCurrentData.value.map(item => item.id)
        if (!ids.includes(item.id) && changeCurrentData.value.length <= 25) {
          changeCurrentData.value.push(item)
        }
      }
    } else {
      shopTableData.value.forEach(row => {
        deleteSelectedData(row)
      })
    }
  }
})

// 选择回显
const selectItem = computed(() => {
  return row => {
    if (!changeCurrentData.value) return false
    return changeCurrentData.value.some(item => item.id == row.id)
  }
})

// 单选
const handleSelect = ($event, row) => {
  if ($event && changeCurrentData.value.length <= 25) {
    changeCurrentData.value.push(row)
  } else {
    deleteSelectedData(row)
  }
}

// 删除
const deleteSelectedData = row => {
  const index = changeCurrentData.value.findIndex(item => item.id == row.id)
  if (index != -1) {
    changeCurrentData?.value.splice(index, 1)
  }
}

// 可按名称模糊匹配
const handleChangeExternal = val => {
  let item = externalIdList.value.filter(item => item.groupId == val)
  form.value.content = item[0].description
  // 使用范围
  form.value.useGoodsType = rangeType(item[0].rangeType)
  // 门槛设置
  form.value.conditionType = item[0].isAtLeast + 1
  if (form.value.conditionType == 2) {
    form.value.conditionMoney = item[0].atLeast
  }
  // 卡券金额
  form.value.money = item[0].value
}

// 选中商品确认
const checkoutSubmit = () => {
  specifyTableData.value = []
  if (changeCurrentData.value.length == 0) {
    ElMessage.warning('请选择卡券商品')
    return false
  }
  if (changeCurrentData.value.length <= 25) {
    changeCurrentData.value.forEach(item => {
      specifyTableData.value.push(item)
    })
    cardsVisible.value = false
  } else {
    ElMessage.warning('所选数据超过25条,请重新选择')
  }
}

// 选中商品删除
const handleClickDelete = row => {
  ElMessageBox.confirm(`是否确认删除当前商品？`, '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      specifyTableData.value = specifyTableData.value.filter(
        item => item.id != row.id
      )
      changeCurrentData.value = changeCurrentData.value.filter(
        item => item.id != row.id
      )
    })
    .catch(() => {})
}

// 清空所有
const headerClick = (column, event) => {
  if (column.no == 1) {
    ElMessageBox.confirm(`是否清空所有商品？`, '提示：', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        specifyTableData.value = []
        changeCurrentData.value = []
      })
      .catch(() => {})
  }
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  }
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataList()
}

const handleSizeChange = val => {
  shopPagination.value.current = 1
  shopPagination.value.size = val
  getDataList()
}

const handleCurrentChange = val => {
  shopPagination.value.current = val
  getDataList()
}

// 获取列表数据
const getDataList = () => {
  shopLoading.value = true
  const data = {
    current: shopPagination.value.current,
    size: shopPagination.value.size,
    state: 1,
    payType: 1
  }
  if (fromData.value.keyword !== '') {
    data.keyword = fromData.value.keyword
  }
  if (fromData.value.goodsCategoryId !== '') {
    data.goodsCategoryId = fromData.value.goodsCategoryId
  }
  queryCriteria(data)
    .then(({ data: res }) => {
      shopLoading.value = false
      if (res.code == 200) {
        shopTableData.value = res.data.records
        shopPagination.value.total = Number(res.data.pageInfo.total)
        // changeCurrentData.value = specifyTableData.value
      } else {
        ElMessage.error(res.msg)
        shopTableData.value = []
        shopPagination.value.total = 0
      }
    })
    .catch(() => {
      shopLoading.value = false
    })
}

// 获取分类列表数据
const getClassifyDataList = (val, flag) => {
  goodsCategoryTree().then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      formLabels.value[0].options = list
    } else {
      ElMessage.error(res.msg)
      formLabels.value[0].options = []
    }
  })
}

// 商品价格
const initPrice = row => {
  let val = `${row.minPrice} ~ ${row.maxPrice}`
  if (row.minPrice == row.maxPrice) {
    val = row.minPrice
  }
  return val
}
</script>
<style lang="scss" scoped>
.common {
  h6 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  :deep(.el-form) {
    .form_date {
      display: inline-flex;
    }
    .el-input,
    .el-select {
      width: 200px;
      margin-right: 10px;
    }
    .el-date-editor {
      width: 400px;
    }
    .tables {
      width: 600px;
    }
    .mb-0 {
      margin-bottom: 0;
    }
    .el-textarea {
      width: 300px;
    }
  }
  :deep(.form_date_table) {
    .el-table {
      th:nth-child(2) {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}

.desc {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  span {
    width: 300px;
    text-align: left;
  }
}
.common {
  position: relative;
}
.commonmask {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}

.backall {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 999;
}
</style>
