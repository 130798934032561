import request from '../Axios'

// 列表
export const queryCriteria = data => {
  return request({
    url: '/goods/query/criteria',
    method: 'post',
    data
  })
}

export const queryCriteriaSpecify = data => {
  return request({
    url: '/goods/query/criteria',
    method: 'post',
    data
  })
}

// 商品新增
export const goodsCreate = data => {
  return request({
    url: '/goods/create',
    method: 'post',
    data
  })
}

// 商品修改
export const goodsUpdate = data => {
  return request({
    url: '/goods/update',
    method: 'put',
    data
  })
}

// 商品详情
export const goodsDetail = params => {
  return request({
    url: `/goods/admin/detail/${params.id}`,
    method: 'GET'
  })
}

// 商品删除
export const goodsDel= params => {
  return request({
    url: `/goods/del/${params.id}`,
    method: 'delete'
  })
}

// 变更商品状态
export const changeState = params => {
  return request({
    url: `/goods/change/state/${params.id}`,
    method: 'put'
  })
}

// 商品排序
export const goodsSort = data => {
  return request({
    url: `/goods/sort/${data.id}`,
    method: 'GET',
    params: {
      sort: data.sort
    }
  })
}

// 设置、取消
export const setGood = params => {
  return request({
    url: `/goods/recommend/${params.id}`,
    method: 'put'
  })
}

// 商品打标签
export const goodsTagCreate = data => {
  return request({
    url: '/goods/tag/attach',
    method: 'post',
    data
  })
}

// 修改商品标签
export const goodsTagUpdate = data => {
  return request({
    url: '/goods/tag/update',
    method: 'put',
    data
  })
}

// 删除商品标签
export const goodsTagDel= params => {
  return request({
    url: `/goods/tag/del/${params.id}`,
    method: 'delete'
  })
}

// 获取会员等级
export const queryLevel = () => {
  return request({
    url: `/constant/query/Level`,
    method: 'GET'
  })
}